<template>
    <div>
      <v-select
        v-model="selectedRoute"
        :options="routeOptions"
        @input="handleSelect"
        style="width: 20em;"
        label="name"
        :placeholder="$t('others.menu_search')"
        :loading="loading"
        :searchable="true"
        :noDataText="$t('others.search_not_found')"
        :loadingText="$t('others.loading')"
        @search="handleSearch"
      ></v-select>
    </div>
  </template>
  
  <script>
  import VSelect from 'vue-select';
  import 'vue-select/dist/vue-select.css';
  import router from '@/router';
  
  export default {
    components: {
      VSelect
    },
    data() {
      return {
        selectedRoute: null,
        routeOptions: [],
        loading: false,
        searchTerm: ''
      };
    },
    methods: {
      handleSearch(search) {
        this.searchTerm = search;
        if (search.length >= 3) {
          this.loading = true;
          this.fetchRoutes(search);
        } else {
          this.routeOptions = [];
          this.loading = false;
        }
      },
      fetchRoutes(search) {
        setTimeout(() => {
          this.routeOptions = router.options.routes
            .filter(route => route.name && route.name.toLowerCase().includes(search.toLowerCase()))
            .map(route => ({
              value: route.name, // Burada value olarak route ismi tanımlı
              name: route.name   // Görüntüleme için name kullanılıyor
            }));
          this.loading = false;
        }, 500);
      },
      handleSelect(selected) {
        if (selected && this.$route.name !== selected.value) {
          this.$router.push({ name: selected.value }).catch(err => {
            if (err.name !== 'NavigationDuplicated') {
              console.error(err);
            }
          });
        }
      }
    }
  };
  </script>
  
  
  