<template>
<div class="navbar-container d-flex content align-items-center">
    <trademark-form ref="trademarkForm"></trademark-form>
    <invoice-form ref="invoiceForm"></invoice-form>
    <patent-form ref="patentForm"></patent-form>
    <national-patent-form ref="nationalPatentForm"></national-patent-form>
    <trademark-search-form ref="trademarkSearchForm"></trademark-search-form>
    <trademark-opposition-form ref="trademarkOppositionForm"></trademark-opposition-form>
    <national-trademark-form ref="nationalTrademarkForm"></national-trademark-form>
    <design-form ref="designForm"></design-form>
    <design-opposition-form ref="designOppositionForm"></design-opposition-form>
    <domain-form ref="domainForm"></domain-form>
    <domain-opposition-form ref="domainOppositionForm"></domain-opposition-form>
    <exchange-rate-calculator-form ref="exchangeRateCalculatorForm"></exchange-rate-calculator-form>
    <total-image-upload-form ref="totalImageUploadForm"></total-image-upload-form>
    <client-form ref="clientForm"></client-form>
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
        <li class="nav-item">
            <b-link class="nav-link" @click="toggleVerticalMenuActive" v-model="menuIcon">
                <feather-icon icon="MenuIcon" size="21" ref="menuIcon" />
            </b-link>
        </li>
    </ul>
    <b-navbar-nav class="nav align-items-center w-100">
        <!--Search Sekmesi-->
        <div class="d-flex align-items-center mr-auto">
            <b-col class="col-10">
                <v-select v-model="searchRecordSelect" :options="searchRecordOption.map(option => ({ ...option, recordName: $truncateText(option.recordName, 50)}))" :placeholder="$t('others.search')" @input="openForm" label="recordName" @search="onFastSearch">
                </v-select>
            </b-col>
            <b-col class="col-2">
                <SearchBar />
            </b-col>
        </div>

        <!-- Diğer Sekmeler -->
        <div class="d-flex align-items-center ml-auto">
            <!--Zamanlayıcı Sekmesi-->
            <span class="mr-3">
                <BaseTimer />
            </span>

            <!--Dil Sekmesi-->
            <b-nav-item-dropdown id="dropdown-grouped" variant="link" class="dropdown-language" right>
                <template #button-content>
                    <b-img :src="currentLocale.img" height="14px" width="22px" :alt="currentLocale.locale" />
                    <span class="ml-50 text-body">{{ currentLocale.name }}</span>
                </template>
                <b-dropdown-item v-for="localeObj in locales" :key="localeObj.locale" @click="changeLanguage(localeObj.locale)">
                    <b-img :src="localeObj.img" height="14px" width="22px" :alt="localeObj.locale" />
                    <span class="ml-50">{{ localeObj.name }}</span>
                </b-dropdown-item>
            </b-nav-item-dropdown>

            <!--Ayarlar Sekmesi-->
            <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
                <template #button-content>
                    <div class="d-flex align-items-center">
                        <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
                        <span>{{ $t('others.tools') }}</span>
                    </div>
                </template>
                <b-dropdown-item @click="openExchangeRateModal" link-class="d-flex align-items-center">
                    <feather-icon size="16" icon="ActivityIcon" class="mr-50" />
                    <span>{{ $t('ExchangeRateCalculator') }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="openTotalImageUpload" link-class="d-flex align-items-center">
                    <feather-icon size="16" icon="ImageIcon" class="mr-50" />
                    <span>{{ $t('TotalImageUpload') }}</span>
                </b-dropdown-item>
            </b-nav-item-dropdown>

            <!--Profil Sekmesi-->
            <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
                <template #button-content>
                    <div class="d-sm-flex d-none user-nav">
                        <p class="user-name font-weight-bolder mb-0">
                            {{name + ' ' + surname}}
                        </p>
                        <span class="user-status">{{title}}</span>
                    </div>
                    <b-avatar size="40" variant="light-primary" badge :src="imageSrc" class="badge-minimal" badge-variant="success" />
                </template>
                <b-dropdown-item to="MyAccount" link-class="d-flex align-items-center">
                    <feather-icon size="16" icon="UserIcon" class="mr-50" />
                    <span>{{ $t('Account') }}</span>
                </b-dropdown-item>
                <b-dropdown-item to="HourlyAnnualLeaveByEmployee" link-class="d-flex align-items-center">
                    <feather-icon size="16" icon="WatchIcon" class="mr-50" />
                    <span>{{ $t('HourlyAnnualLeave') }}</span>
                </b-dropdown-item>
                <b-dropdown-item to="AnnualLeaveByEmployee" link-class="d-flex align-items-center">
                    <feather-icon size="16" icon="ClockIcon" class="mr-50" />
                    <span>{{ $t('AnnualLeave') }}</span>
                </b-dropdown-item>
                <b-dropdown-item to="OvertimeWorkListForEmployee" link-class="d-flex align-items-center">
                    <feather-icon size="16" icon="MoonIcon" class="mr-50" />
                    <span>{{ $t('Overtime Work') }}</span>
                </b-dropdown-item>
                <b-dropdown-item to="exit" link-class="d-flex align-items-center">
                    <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                    <span>{{ $t('Exit') }}</span>
                </b-dropdown-item>
            </b-nav-item-dropdown>

            <!-- Boş Dropdown-->
            <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user"></b-nav-item-dropdown>
        </div>
    </b-navbar-nav>
</div>
</template>

<script>
import {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BImg
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import {
    localize
} from 'vee-validate';
import BaseTimer from "./BaseTimer.vue";
import SearchBar from "./SearchBar.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    components: {
        BLink,
        BNavbarNav,
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,
        BImg,
        BaseTimer,
        SearchBar,
        Loading,
        vSelect,

        // Navbar Components
        DarkToggler,
    },
    data() {
        return {
            isHidden: null,
            menuIcon: false,
            locale: this.$i18n.locale,
            name: '',
            surname: '',
            title: '',
            imageSrc: '',
            searchRecordOption: [],
            searchRecordSelect: 0
        }
    },
    mounted() {
        this.name = localStorage.getItem('Name')
        this.surname = localStorage.getItem('Surname')
        this.title = localStorage.getItem('Title')

        var defaultlanguage = "tr"
        // if(this.$cookies.get('languageValue') != null)
        //    defaultlanguage = this.$cookies.get('languageValue');
        if (sessionStorage.getItem('languageValue') != null)
            defaultlanguage = sessionStorage.getItem('languageValue');
        this.changeLanguage(defaultlanguage);

        if (localStorage.getItem('employeePhoto') != "")
            this.imageSrc = 'data:image/png;base64,' + localStorage.getItem('employeePhoto')
        else
            this.imageSrc = require('@/assets/images/logoUser.jpeg')

    },
    methods: {
        openExchangeRateModal() {
            this.$refs.exchangeRateCalculatorForm.showInfo()
        },
        openTotalImageUpload() {
            this.$refs.totalImageUploadForm.showInfo()
        },
        changeLanguage(value) {
            this.$i18n.locale = value;
            sessionStorage.setItem("language", value)
            localize(value);
            // localStorage.language = value
            sessionStorage.setItem('languageValue', value)
            //this.$cookies.set('languageValue', value)
        },
        onFastSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.fastSearch(loading, search, this);
            }
        },
        fastSearch(loading, search) {
            this.searchRecordSelect = 0
            if (search.length > 2) {
                const data = {
                    recordNo: search,
                    isOnlyOperationRecord: false
                }

                const controller = {
                    name: 'Module',
                    actionName: 'GetRecordListByRecordNo'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.searchRecordOption = response.data.recordList
                    loading(false)
                })
            }
        },
        openForm(event) {
            this.searchRecordSelect = 0
            const moduleId = event.moduleId
            const recordId = event.recordId
            if (moduleId == 1) {
                this.$refs.clientForm.showInfo(recordId)
            } else if (moduleId == 2) {
                this.$refs.trademarkForm.showInfo(recordId)
            } else if (moduleId == 3) {
                this.$refs.trademarkSearchForm.showInfo(recordId)
            } else if (moduleId == 4) {
                this.$refs.patentForm.showInfo(recordId)
            } else if (moduleId == 5) {
                this.$refs.designForm.showInfo(recordId)
            } else if (moduleId == 6) {
                this.$refs.trademarkOppositionForm.showInfo(recordId)
            } else if (moduleId == 7) {
                this.$refs.domainForm.showInfo(recordId)
            } else if (moduleId == 8) {
                this.$refs.domainForm.showInfo(recordId)
            } else if (moduleId == 9) {
                this.$refs.designOppositionForm.showInfo(recordId)
            } else if (moduleId == 15) {
                this.$refs.nationalTrademarkForm.showInfo(recordId)
            } else if (moduleId == 16) {
                this.$refs.nationalPatentForm.showInfo(recordId)
            } else if (moduleId == 20) {
                this.$refs.invoiceForm.showInfo(recordId)
            }
        },
    },
    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {},
        },
    },
    computed: {
        currentLocale() {
            return this.locales.find(l => l.locale === this.$i18n.locale)
        },
    },
    setup() {
        /* eslint-disable global-require */
        const locales = [{
                locale: 'tr',
                img: require('@/assets/images/flags/tr.png'),
                name: 'Türkçe',
            },
            {
                locale: 'en',
                img: require('@/assets/images/flags/en.png'),
                name: 'English',
            },
        ]
        /* eslint-disable global-require */

        return {
            locales,
        }
    },
}
</script>

<style lang="scss">
.base-timer[data-v-f673a68c] {
    position: relative;
    width: 40px;
    height: 40px;
}

.base-timer__label[data-v-f673a68c] {
    position: absolute;
    width: 45px;
    height: 40px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.recordSearch {
    display: flex;
    max-width: 17em;
    width: 17em;
    max-height: 3em;
    height: 2em;
    overflow-y: clip;
}
</style>
