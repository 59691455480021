export default [] = getMenu(localStorage.getItem("RoleId"), localStorage.getItem("DepartmentId"));

function getMenu(roleId, departmentId) {
  let menu = [
    {
      title: "Information Panel",
      icon: "HomeIcon",
      route: "Home",
    },
    {
      title: "Client Transactions",
      icon: "BookOpenIcon",
      children: [
        {
          title: "Client List",
          route: "Client",
        },
        {
          title: "Reports",
          children: [
            {
              title: "Client Responsible Report",
              route: "ClientResponsibleReport",
            },
            {
              title: "Different Invoice Report",
              route: "ClientAccountingDifferentInvoiceReport",
            },
            {
              title: "Special Invoice Report",
              route: "ClientAccountingSpecialInvoiceReport",
            },
            {
              title: "Invoice Alert Day Report",
              route: "ClientAccountingInvoiceAlertDayReport",
            },
            {
              title: "Agent Transactions Report",
              route: "AgentTransactionsReport",
            },
          ],
        },
      ],
    },
    {
      title: "Domain Transactions",
      icon: "Link2Icon",
      children: [
        {
          title: "Domain List",
          route: "Domain",
        },
        {
          title: "Domain Opposition",
          route: "DomainOpposition",
        },
      ],
    },
    {
      title: "Design Transactions",
      icon: "CodepenIcon",
      children: [
        {
          title: "Design List",
          route: "Design",
        },
        {
          title: "Design Opposition List",
          route: "DesignOpposition",
        },
        {
          title: "Reports",
          children: [
            {
              title: "Deadline Report",
              route: "DesignDeadlineReport",
            },
            {
              title: "Bulletin Check Report",
              route: "DesignBulletinCheckReport",
            },
            {
              title: "Bulletin Analyse",
              route: "DesignBulletinAnalyseReport",
            },
          ],
        },
      ],
    },
    {
      title: "Trademark Transactions",
      icon: "GridIcon",
      children: [
        {
          title: "Trademark List",
          route: "Trademark",
        },
        {
          title: "National Trademark List",
          route: "NationalTrademark",
        },
        {
          title: "Trademark Opposition List",
          route: "TrademarkOpposition",
        },
        {
          title: "Trademark Search",
          route: "TrademarkSearch",
        },
        {
          title: "National Trademark Search",
          route: "NationalTrademarkSearch",
        },
        {
          title: "Reports",
          children: [
            {
              title: "Trademark Transaction Report",
              route: "TrademarkTransactionReport",
            },
            {
              title: "Bulletin Analyse",
              route: "TrademarkBulletinAnalyseReport",
            },
            {
              title: "Trademark Bulletin Check",
              route: "TrademarkBulletinCheck",
            },
          ],
        },
      ],
    },
    {
      title: "Patent Transactions",
      icon: "Share2Icon",
      children: [
        {
          title: "Patent List",
          route: "Patent",
        },
        {
          title: "National Patent List",
          route: "NationalPatent",
        },
        {
          title: "Patent Update Fields",
          route: "PatentUpdateFields",
        },
        {
          title: "Reports",
          children: [
            {
              title: "Patent Transaction Report",
              route: "PatentTransactionReport",
            },
            {
              title: "Bulletin Analyse",
              route: "PatentBulletinAnalyseReport",
            },
            {
              title: "Patent Bulletin Check",
              route: "PatentBulletinCheck",
            },
            {
              title: "Patent Certificate",
              route: "PatentCertificate",
            },
            {
              title: "Patent Working Reminder",
              route: "PatentWorkingReminder",
            },
            {
              title: "Patent Annual Fee Reminder",
              route: "PatentAnnualFeeReminder",
            },
          ],
        },
      ],
    },
    {
      title: "TPMK",
      icon: "ChevronsUpIcon",
      children: [
        {
          title: "Etebs",
          route: "Etebs",
        },
      ],
    },
    {
      title: "Translator Transactions",
      icon: "MicIcon",
      children: [
        {
          title: "Translator List",
          route: "Translator",
        },
        {
          title: "Translate Report",
          route: "TranslateReport",
        },
      ],
    },
    {
      title: "External Services",
      icon: "ExternalLinkIcon",
      children: [
        {
          title: "Lexology",
          route: "Lexology",
        },
      ],
    },
    {
      title: "Reports",
      icon: "BarChartIcon",
      children: [
        {
          title: "Mail History",
          route: "MailHistory",
        },
      ],
    },
    {
      title: "Finance",
      icon: "DollarSignIcon",
      children: [
        {
          title: "Invoice",
          icon: "BookIcon",
          route: "Invoice",
        },
        {
          title: "OfficialFee",
          icon: "TrendingUpIcon",
          route: "OfficialFee",
        },
      ],
    },
    {
      title: "Annuity Transactions",
      icon: "FileIcon",
      children: [
        {
          title: "Agent Annuity Price",
          route: "AgentAnnuityPrice",
        },
        {
          title: "Annuity",
          route: "Annuity",
        },
      ],
    },
  ];

  if (roleId == 1 || roleId == 2) {
    menu.push(
      {
        title: "Human Resources",
        icon: "ThumbsUpIcon",
        children: [
          {
            title: "Employee",
            route: "Employee",
            icon: "UsersIcon",
          },
          {
            title: "EmploymentContract",
            route: "EmploymentContract",
            icon: "ClipboardIcon",
          },
          {
            title: "HourlyAnnualLeave",
            route: "EmployeeHourlyAnnualLeave",
            icon: "WatchIcon",
          },
          {
            title: "AnnualLeave",
            route: "EmployeeAnnualLeave",
            icon: "ClockIcon",
          },
          {
            title: "Overtime Work",
            route: "OvertimeWorkList",
            icon: "MoonIcon",
          },
          {
            title: "Reports",
            children: [
              {
                title: "Hourly Annual Leave Report",
                route: "HourlyAnnualLeaveReport",
              },
              {
                title: "Annual Leave Report",
                route: "AnnualLeaveReport",
              },
            ],
          },
        ],
      },
      {
        title: "Definitions",
        icon: "ListIcon",
        children: [
          {
            title: "Operation",
            icon: "ActivityIcon",
            children: [
              {
                title: "Job Status Relation",
                route: "JobStatusRelation",
              },
              {
                title: "Job Status",
                route: "JobStatus",
              },
              {
                title: "Word Templates",
                route: "WordTemplates",
              },
              {
                title: "Document Types",
                route: "DocumentTypes",
              },
              {
                title: "Patent Note Type",
                route: "PatentNoteType",
              },
              {
                title: "Patent Annuity Will Pay",
                route: "PatentAnnuityWillPay",
              },
              {
                title: "Trademark Application Type",
                route: "TrademarkApplicationType",
              },
              {
                title: "National Trademark Application Type",
                route: "NationalTrademarkApplicationType",
              },
              {
                title: "Trademark Type",
                route: "TrademarkType",
              },
              {
                title: "Trademark Search Database",
                route: "TrademarkSearchDatabase",
              },
              {
                title: "Trademark Search Success Rate",
                route: "TrademarkSearchSuccessRate",
              },
              {
                title: "Nice Class Type",
                route: "NiceClassType",
              },
              {
                title: "Trademark Search Type",
                route: "TrademarkSearchType",
              },
              {
                title: "Trademark Note Type",
                route: "TrademarkNoteType",
              },
              {
                title: "Patent Status",
                route: "PatentStatus",
              },
              {
                title: "Annuity Detail Status",
                route: "AnnuityDetailStatus",
              },
              {
                title: "Design Status",
                route: "DesignStatus",
              },
              {
                title: "Patent Annuity Status",
                route: "PatentAnnuityStatus",
              },
              {
                title: "Trademark Status",
                route: "TrademarkStatus",
              },
              {
                title: "Trademark Search Status",
                route: "TrademarkSearchStatus",
              },
              {
                title: "Patent Search Database",
                route: "PatentSearchDatabase",
              },
              {
                title: "Trademark Objection To Opposing View Status",
                route: "TrademarkObjectionToOpposingViewStatus",
              },
              {
                title: "Trademark Objection To Us Case Decision",
                route: "TrademarkObjectionToUsCaseDecision",
              },
              {
                title: "Trademark Objection To Us Decision",
                route: "TrademarkObjectionToUsDecision",
              },
              {
                title: "Trademark Objection To Us Status",
                route: "TrademarkObjectionToUsStatus",
              },
              {
                title: "Trademark Objection To Us Yidk Status",
                route: "TrademarkObjectionToUsYidkStatus",
              },
              {
                title: "Trademark Provisional Case Instruction",
                route: "TrademarkProvisionalCaseInstruction",
              },
              {
                title: "Trademark Provisional Status",
                route: "TrademarkProvisionalStatus",
              },
              {
                title: "Trademark Opposition Status",
                route: "TrademarkOppositionStatus",
              },
              {
                title: "National Trademark Search Status",
                route: "NationalTrademarkSearchStatus",
              },
            ],
          },
          {
            title: "Finance",
            icon: "ApertureIcon",
            children: [
              {
                title: "Note To Be On Invoice",
                route: "NoteToBeOnInvoice",
              },
              {
                title: "Price Lists",
                route: "PriceLists",
              },
              {
                title: "Invoice Header",
                route: "InvoiceHeader",
              },
              {
                title: "Invoice Status",
                route: "InvoiceStatus",
              },
              {
                title: "Billing Status",
                route: "BillingStatus",
              },
              {
                title: "Ledes Code Type",
                route: "LedesCodeType",
              },
              {
                title: "Ledes Type",
                route: "LedesType",
              },
              {
                title: "Service Types",
                route: "ServiceTypes",
              },
              {
                title: "Services",
                route: "Services",
              },
              {
                title: "ServiceFees",
                route: "ServiceFees",
              },
              {
                title: "Credit Card",
                route: "CreditCard",
              },
              {
                title: "Client Accounting Category",
                route: "ClientAccountingCategory",
              },
              {
                title: "Invoice Discount Format",
                route: "InvoiceDiscountFormat",
              },
              {
                title: "Bank",
                route: "Bank",
              },
            ],
          },
          {
            title: "Human Resources",
            icon: "GlobeIcon",
            children: [
              {
                title: "Departments",
                route: "Departments",
              },
              {
                title: "Permission Template",
                route: "PermissionTemplate",
              },
              {
                title: "EmploymentContractType",
                route: "EmploymentContractType",
              },
              {
                title: "Annual Leave Type",
                route: "AnnualLeaveType",
              },
              {
                title: "Positions",
                route: "Positions",
              },
              {
                title: "Titles",
                route: "Titles",
              },
              {
                title: "Liable Fields",
                route: "LiableFields",
              },
              {
                title: "Holidays",
                route: "Holidays",
              },
              {
                title: "Overtime Work Reason",
                route: "OvertimeWorkReason",
              },
              {
                title: "Overtime Work Type",
                route: "OvertimeWorkType",
              },
            ],
          },
          {
            title: "Mail Account",
            route: "MailAccount",
            icon: "MailIcon",
          },
          {
            title: "Mail Template",
            route: "MailTemplate",
            icon: "VoicemailIcon",
          },
          {
            title: "Employee Classification",
            route: "EmployeeClassification",
            icon: "UserPlusIcon",
          },
          {
            title: "Country",
            route: "Country",
            icon: "MapIcon",
          },
          {
            title: "Contact Types",
            route: "ContactTypes",
            icon: "PhoneIncomingIcon",
          },
          {
            title: "Mail Contact Types",
            route: "MailContactTypes",
            icon: "MessageSquareIcon",
          },
          {
            title: "Invoice Send Types",
            route: "InvoiceSendTypes",
            icon: "ShoppingBagIcon",
          },
          {
            title: "Responsible Fields",
            route: "ResponsibleFields",
            icon: "FilmIcon",
          },
          {
            title: "Languages",
            route: "Languages",
            icon: "GlobeIcon",
          },
          {
            title: "Sectors",
            route: "Sectors",
            icon: "GlobeIcon",
          },
          {
            title: "Note Type",
            route: "NoteType",
            icon: "Edit2Icon",
          },
          {
            title: "Mail To Read",
            route: "MailToRead",
            icon: "MailIcon",
          },
          // {
          //   title: "FTP Client Test",
          //   route: "FTPClientTest",
          //   icon: "UserCheckIcon",
          // },
        ],
      }
    );
  }
  if (roleId == 1) {
    menu.push(
      {
        title: "Developers",
        icon: "CodeIcon",
        children: [
          {
            title: "Permission Group",
            route: "PermissionGroup",
            icon: "BriefcaseIcon",
          },
          {
            title: "Permission",
            route: "Permission",
            icon: "LockIcon",
          },
          {
            title: "Time Type",
            route: "TimeType",
            icon: "ClockIcon",
          },
          {
            title: "Job Reminder Criterion",
            route: "JobReminderCriterion",
            icon: "RewindIcon",
          },
          {
            title: "Annual Leave Right",
            route: "AnnualLeaveRight",
            icon: "TagIcon",
          },
          {
            title: "Annual Leave Status",
            route: "AnnualLeaveStatus",
            icon: "BookIcon",
          },
          {
            title: "Bulletin Upload",
            icon: "TargetIcon",
            route: "BulletinUpload",
          },
        ],
      },
      {
        title: "Admin",
        icon: "UnlockIcon",
        children: [
          {
            title: "Admin Hourly Annual Leave",
            route: "AdminEmployeeHourlyAnnualLeave",
            icon: "WatchIcon",
          },
          {
            title: "Admin Annual Leave",
            route: "AdminEmployeeAnnualLeave",
            icon: "ClockIcon",
          },
          {
            title: "Admin Overtime Work",
            route: "OvertimeWorkListForManager",
            icon: "MoonIcon",
          },
        ],
      }
    );
  }
  if (roleId == 1 || departmentId == 2) {
    menu.filter(p=>p.title == "Finance")[0].children.push(
      {
        title: "Accounting",
        icon: "BarChartIcon",
        children: [
          {
            title: "Payment",
            route: "Payment",
          },
          {
            title: "TranslatorPayments",
            route: "TranslatorPayments",
          },
          {
            title: "Ledes",
            route: "Ledes",
          },
          {
            title: "InvoiceApproval",
            route: "InvoiceApproval",
          },
          {
            title: "EInvoice",
            route: "EInvoice",
          },
          {
            title: "AccountingBooks",
            route: "AccountingBooks",
          },
          {
            title: "Statement",
            route: "Statement",
          },
          {
            title : "InvoiceAgingReport",
            route : "InvoiceAgingReport"
          }
        ],
      },
    )
    
  }
  return menu;
}
